.app_code__container {
  width: 100%;
  background-color: #EDEBE9;
  padding: 2px 8px 6px;
  overflow: auto;
  position: relative;
}

.app_code__copy_btn {
  position: absolute;
  right: 4px;
  top: 4px;
}

.app_code__pre {
  margin: 0;
}
