.single_partner__partner_info__email {
  font-size: 28px;
  word-break: break-word;
}

.single_partner__partner_info__detail_title {
  color: #687385;
  font-size: 14px;
  margin-top: 12px;
}

.single_partner__partner_info__detail_value {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
