.layout__sider {
  height: 100vh;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
}

.layout__sider__project_title {
  padding: 20px;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout__content_layout__header {
  background-color: #FFF;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.layout__content_layout__header__user_dropdown__icon_container {
  padding: 12px;
  border: 1px solid #d7d7d7;
  text-align: center;
  margin: 8px 0;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
}

.layout__content_layout__page_content {
  background-color: #FFF;
  padding: 20px 50px;
}
