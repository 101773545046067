.add_partner_form__wrapper {
  margin-top: 40px;
}

.add_partner_form__balance_input {
  width: 100%;
}

.add_partner_form__submit_btn {
  width: 100%;
}
